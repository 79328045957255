import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import Select from 'react-select';

import { ValidationError } from '../../components';
import {
  ATTENTION_COLOR,
  FAIL_COLOR,
  MATTER_COLOR,
  MATTER_COLOR_ANTI,
  MATTER_COLOR_DARK,
  MATTER_COLOR_LIGHT,
  SUCCESS_COLOR,
} from '../../util/colors';

const FieldSelectComponent = props => {
  const { rootClassName, className, id, label, input, meta, children, ...rest } = props;

  if (label && !id) {
    throw new Error('id required when a label is given');
  }

  const selectProps = { id, ...input, ...rest };

  const { touched, error } = meta;

  let bottomBorderColor = SUCCESS_COLOR;

  if (!touched && error) {
    bottomBorderColor = ATTENTION_COLOR;
  } else if (touched && error) {
    bottomBorderColor = FAIL_COLOR;
  }

  const colourStyles = {
    container: (styles, { isDisabled }) => ({
      ...styles,
      pointerEvents: 'auto',
    }),
    clearIndicator: styles => ({
      ...styles,
      color: MATTER_COLOR,
      ':hover': {
        cursor: 'pointer',
      },
    }),
    control: (styles, { isDisabled, isFocused }) => {
      return {
        ...styles,
        backgroundColor: 'white',
        borderTop: 'none',
        borderRight: 'none',
        borderLeft: 'none',
        borderBottom: isDisabled
          ? `2px solid ${MATTER_COLOR_ANTI}`
          : `2px solid ${bottomBorderColor}`,
        borderRadius: 'none',
        boxShadow: 'none',
        outline: isFocused ? '5px auto -webkit-focus-ring-color' : 0,

        ':hover': {
          ...styles[':focus'],
          borderTop: 'none',
          borderRight: 'none',
          borderLeft: 'none',
          borderBottom: isDisabled
            ? `2px solid ${MATTER_COLOR_ANTI}`
            : `2px solid ${MATTER_COLOR_DARK}`,
          borderRadius: 'none',
          boxShadow: 'none',
          cursor: isDisabled ? 'not-allowed' : 'pointer',
        },
      };
    },
    dropdownIndicator: (styles, { isDisabled }) => ({
      ...styles,
      color: MATTER_COLOR,
      ':hover': {
        cursor: isDisabled ? 'not-allowed' : 'pointer',
      },
    }),
    option: (styles, { isDisabled }) => {
      return {
        ...styles,
        cursor: isDisabled ? 'not-allowed' : 'pointer',
      };
    },
    multiValueRemove: (styles, { isDisabled }) => ({
      ...styles,
      ':hover': {
        backgroundColor: MATTER_COLOR_ANTI,
        color: MATTER_COLOR_LIGHT,
        cursor: isDisabled ? 'not-allowed' : 'pointer',
      },
    }),
    valueContainer: styles => ({
      ...styles,
      paddingLeft: 0,
    }),
  };

  return (
    <div className={rootClassName}>
      {label ? <label htmlFor={id}>{label}</label> : null}
      <Select styles={colourStyles} {...selectProps} />
      <ValidationError fieldMeta={meta} />
    </div>
  );
};

FieldSelectComponent.defaultProps = {
  rootClassName: null,
  className: null,
  id: null,
  label: null,
};

const { string, object, node } = PropTypes;

FieldSelectComponent.propTypes = {
  rootClassName: string,
  className: string,

  // Label is optional, but if it is given, an id is also required so
  // the label can reference the input in the `for` attribute
  id: string,
  label: string,

  // Generated by final-form's Field component
  input: object.isRequired,
  meta: object.isRequired,
};

const FieldSelectV2 = props => {
  return <Field component={FieldSelectComponent} {...props} />;
};

export default FieldSelectV2;
