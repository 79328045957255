import { arrayOf, bool, func, object, shape, string } from 'prop-types';
import React, { Component } from 'react';
import { Form as FinalForm } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';
import { connect } from 'react-redux';

import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';

import { manageDisableScrolling } from '../../ducks/UI.duck';

import {
  FieldRadioButton,
  FieldTextInput,
  Form,
  Modal,
  PrimaryButton,
  SecondaryButton,
} from '../../components';

import moment from 'moment';
import FieldSelectV2 from '../../components/FieldSelect/FieldSelectV2';
import { getEnquiredUsers } from '../../util/api';
import { required } from '../../util/validators';
import css from './SectionCloseListing.module.css';
import { getOtherPublishedListings } from './SectionCloseListing.duck';

const closingReasons = ['Item(s) has not been donated', 'Item(s) is not available anymore'];

const OTHER = 'Other reason';
const DONATED = 'Item(s) has been donated';

const USER_NOT_SHOWN = 'USER_NOT_SHOWN';

export class SectionCloseListingComponent extends Component {
  state = {
    loadingEnquiredUsers: true,
    enquiredUsers: [],
    enquiredUserError: false,
    modalOpen: false,
    activeElement: null,
  };

  async componentDidMount() {
    this.setState({ ...this.state, modalOpen: true, activeElement: document.activeElement });
    try {
      const { enquiredUsers } = await getEnquiredUsers({ listingId: this.props.listingId });
      this.setState({ enquiredUsers });
    } catch {
      this.setState({ enquiredUserError: true });
    } finally {
      this.setState({ loadingEnquiredUsers: false });
    }

    this.props.getOtherPublishedListings(this.props.listingId);
  }

  onSubmit = values => {
    const {
      closeReason,
      otherReason,
      communityPartnerSelect,
      otherListings,
      nameOfRecipient,
    } = values;

    if (
      !closeReason ||
      (closeReason === DONATED && !communityPartnerSelect) ||
      (closeReason === OTHER && !otherReason)
    ) {
      return;
    }

    let communityPartner = null;

    if (closeReason === DONATED) {
      const userNotShownSelected = communityPartnerSelect.value === USER_NOT_SHOWN;
      if (communityPartnerSelect.value === USER_NOT_SHOWN) {
        communityPartner = { displayName: nameOfRecipient, userNotShownSelected };
      } else {
        communityPartner = this.state.enquiredUsers.find(
          user => user.id === communityPartnerSelect.value
        );
      }
    }

    const otherListingIdsToClose = closeReason === DONATED ? otherListings?.map(l => l.value) : [];

    const dateTimeClosed = moment()
      .tz('Australia/Melbourne')
      .format();

    const closed = {
      reason: closeReason,
      otherReason: closeReason === OTHER ? otherReason : null,
      communityPartner,
      dateTimeClosed,
    };

    this.props.onCloseListing(this.props.listingId, closed, otherListingIdsToClose);
  };

  onActivate = () => {
    this.props.onOpenListing(this.props.listingId);
  };

  render() {
    const { enquiredUsers, enquiredUserError, loadingEnquiredUsers } = this.state;
    const {
      getOtherPublishedListingsLoading,
      getOtherPublishedListingsError,
      otherPublishedListings,
      submitInProgress,
      intl,
    } = this.props;

    const showEnquiredUsersError = !loadingEnquiredUsers && enquiredUserError;

    const userNotShownLabel = intl.formatMessage({
      id: 'SectionCloseListing.userNotShownLabel',
    });

    const userNotShownOption = {
      value: USER_NOT_SHOWN,
      label: userNotShownLabel,
    };
    const enquiredUsersOptions = enquiredUsers.map(({ displayName, id }) => ({
      value: id,
      label: displayName,
    }));

    const otherPublishedListingOptions = otherPublishedListings.map(l => ({
      value: l.id,
      label: l.attributes.title,
    }));

    const provideReasonError = intl.formatMessage({
      id: 'SectionCloseListing.provideReasonError',
    });
    const provideCPError = intl.formatMessage({
      id: 'SectionCloseListing.provideCPError',
    });
    const provideNameOfRecipient = intl.formatMessage({
      id: 'SectionCloseListing.provideNameOfRecipient',
    });

    return (
      <div className={css.section}>
        <Modal
          usePortal
          id="CloseListing"
          isOpen={this.state.modalOpen}
          onClose={e => {
            if (submitInProgress) {
              e.preventDefault();
              return;
            }
            this.setState({ modalOpen: false });
            this.props.onCloseModal();
            this.state.activeElement.focus();
          }}
          onManageDisableScrolling={this.props.onManageDisableScrolling}
        >
          <FinalForm
            {...this.props}
            onSubmit={this.onSubmit}
            className={css.root}
            validate={values => {
              const isDonatedSelected = values.closeReason === DONATED;
              const isOtherSelected = values.closeReason === OTHER;
              const otherListings = values.otherListings || [];

              const errors = {};

              if (isDonatedSelected || (isDonatedSelected && otherListings.length)) {
                errors.communityPartnerSelect = required(provideCPError)(
                  values.communityPartnerSelect
                );
              }

              if (isDonatedSelected && values.communityPartnerSelect?.value === USER_NOT_SHOWN) {
                errors.nameOfRecipient = required(provideNameOfRecipient)(values.nameOfRecipient);
              }

              if (isOtherSelected) {
                errors.otherReason = required(provideReasonError)(values.otherReason);
              }

              return errors;
            }}
            render={fieldRenderProps => {
              const {
                handleSubmit,
                values,
                onCloseModal,
                submitInProgress,
                intl,
                hasError,
                form,
              } = fieldRenderProps;

              const isOtherSelected = values.closeReason === OTHER;
              const isDonatedSelected = values.closeReason === DONATED;
              const otherReasonMissing = isOtherSelected && !values.otherReason;
              const userNotShownSelected = values.communityPartnerSelect?.value === USER_NOT_SHOWN;
              const donatedToCP =
                isDonatedSelected && !values.communityPartnerSelect && !userNotShownSelected;
              const recipientNotProvided =
                isDonatedSelected && userNotShownSelected && !values.nameOfRecipient;

              const submitDisabled =
                !values.closeReason ||
                submitInProgress ||
                otherReasonMissing ||
                donatedToCP ||
                recipientNotProvided;

              const otherReasonPlaceholder = intl.formatMessage({
                id: 'SectionCloseListing.otherReasonPlaceholder',
              });

              const resetInputs = () => {
                form.resetFieldState('otherReason');
                form.resetFieldState('communityPartnerSelect');
                form.resetFieldState('otherListings');
              };

              const selectCPPlaceholder = intl.formatMessage({
                id: 'SectionCloseListing.selectCPPlaceholder',
              });
              const selectCPLabel = intl.formatMessage({
                id: 'SectionCloseListing.selectCPLabel',
              });
              const otherPublishedListingsPlaceholder = intl.formatMessage({
                id: 'SectionCloseListing.otherPublishedListingsPlaceholder',
              });
              const otherPublishedListingsLabel = intl.formatMessage({
                id: 'SectionCloseListing.otherPublishedListingsLabel',
              });
              const nameOfRecipient = intl.formatMessage({
                id: 'SectionCloseListing.nameOfRecipient',
              });

              return (
                <div>
                  <Form className={css.root} onSubmit={handleSubmit}>
                    <div>
                      <h1 className={css.title}>
                        <FormattedMessage id="SectionCloseListing.title" />
                      </h1>
                      <div className={css.paragraph}>
                        <FormattedMessage id="SectionCloseListing.description" />
                      </div>
                      <p className={css.selectReason}>
                        <FormattedMessage id="SectionCloseListing.selectReason" />
                      </p>
                      <div>
                        <div>
                          <FieldRadioButton
                            id={`option-reason-donated`}
                            name="closeReason"
                            label={DONATED}
                            value={DONATED}
                          />
                          <div
                            className={values.closeReason === DONATED ? css.cpSelect : css.hidden}
                          >
                            <div className={css.letUsKnowAlert}>
                              <FormattedMessage id="SectionCloseListing.whoReceivedDonationPrompt" />
                            </div>

                            <FieldSelectV2
                              name="communityPartnerSelect"
                              isSearchable={false}
                              options={enquiredUsersOptions.concat([userNotShownOption])}
                              placeholder={selectCPPlaceholder}
                              isDisabled={loadingEnquiredUsers || showEnquiredUsersError}
                              isLoading={loadingEnquiredUsers}
                              id="communityPartnerSelect"
                              label={selectCPLabel}
                            />
                            {showEnquiredUsersError ? (
                              <div className={css.enquiredUsersError}>
                                <FormattedMessage id="SectionCloseListing.enquiredUserError" />
                              </div>
                            ) : null}

                            {userNotShownSelected ? (
                              <FieldTextInput
                                className={css.nameOfRecipientInput}
                                type="text"
                                name="nameOfRecipient"
                                placeholder={nameOfRecipient}
                              />
                            ) : null}

                            <FieldSelectV2
                              isMulti
                              isSearchable={false}
                              name="otherListings"
                              options={otherPublishedListingOptions}
                              rootClassName={css.otherListingsContainer}
                              className="otherListingsContainer"
                              classNamePrefix="other-listings"
                              placeholder={otherPublishedListingsPlaceholder}
                              isDisabled={
                                getOtherPublishedListingsLoading || getOtherPublishedListingsError
                              }
                              isLoading={getOtherPublishedListingsLoading}
                              id="otherListingsMultiSelect"
                              label={otherPublishedListingsLabel}
                            />
                            {getOtherPublishedListingsError ? (
                              <div className={css.enquiredUsersError}>
                                <FormattedMessage id="SectionCloseListing.otherPublishedListingsError" />
                              </div>
                            ) : null}
                          </div>
                        </div>
                        {closingReasons.map((reason, i) => (
                          <FieldRadioButton
                            key={`option-reason${i}`}
                            id={`option-reason${i}`}
                            name="closeReason"
                            label={reason}
                            value={reason}
                          />
                        ))}

                        <div>
                          <FieldRadioButton
                            id={`option-reason-other`}
                            name="closeReason"
                            label={OTHER}
                            value={OTHER}
                          />
                          <div className={values.closeReason === OTHER ? css.other : css.hidden}>
                            <div className={css.letUsKnowAlert}>
                              <FormattedMessage id="SectionCloseListing.otherReasonPrompt" />
                            </div>
                            <FieldTextInput
                              type="text"
                              name="otherReason"
                              placeholder={otherReasonPlaceholder}
                              disabled={!isOtherSelected}
                              hideErrorMessage={!isOtherSelected}
                            />
                          </div>
                        </div>
                      </div>

                      <div className={css.submitContainer}>
                        {hasError ? (
                          <p className={css.closeError}>
                            <FormattedMessage id="SectionCloseListing.somethingWentWrong" />
                          </p>
                        ) : null}
                        <div className={css.submitButtons}>
                          <PrimaryButton
                            type="submit"
                            disabled={submitDisabled}
                            inProgress={submitInProgress}
                          >
                            <FormattedMessage id="SectionCloseListing.closeListing" />
                          </PrimaryButton>
                          <SecondaryButton
                            disabled={submitInProgress}
                            className={css.cancelButton}
                            onClick={onCloseModal}
                            type="button"
                          >
                            <FormattedMessage id="SectionCloseListing.cancel" />
                          </SecondaryButton>
                        </div>
                      </div>
                    </div>
                    <OnChange name="closeReason">{resetInputs}</OnChange>
                  </Form>
                </div>
              );
            }}
          />
        </Modal>
      </div>
    );
  }
}

SectionCloseListingComponent.propTypes = {
  isModalOpen: bool.isRequired,
  submitInProgress: bool.isRequired,
  hasError: bool.isRequired,
  onManageDisableScrolling: func.isRequired,
  onCloseModal: func.isRequired,
  onCloseListing: func.isRequired,
  intl: intlShape.isRequired,
  getOtherPublishedListingsLoading: bool.isRequired,
  getOtherPublishedListingsError: bool.isRequired,
  otherPublishedListings: arrayOf(shape({ id: object.isRequired })).isRequired,
  getOtherPublishedListings: func.isRequired,
  listingId: object.isRequired,
};

const mapStateToProps = state => {
  const {
    getOtherPublishedListingsLoading,
    getOtherPublishedListingsError,
    otherPublishedListings,
  } = state.SectionCloseListing;

  return {
    getOtherPublishedListingsLoading,
    getOtherPublishedListingsError,
    otherPublishedListings,
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  getOtherPublishedListings: currentListingId =>
    dispatch(getOtherPublishedListings(currentListingId)),
});

const SectionCloseListing = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl
)(SectionCloseListingComponent);

export default SectionCloseListing;
