import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { IconCheckmark } from '../../../components';
import { intlShape } from '../../../util/reactIntl';

import css from './EditListingWizard.module.css';

const ThingsToRemember = ({ isElectrical, intl, className }) => {
  const warningLabelLink = intl.formatMessage({
    id: 'EditListingPhotosForm.testingAndTaggingFileLink',
  });

  const warningLabel = (
    <a href={warningLabelLink}>
      <FormattedMessage id="ThingsToRemember.warningLabel" />
    </a>
  );
  return (
    <div className={classNames(css.thingsToRemember, className)}>
      <FormattedMessage id="ThingsToRemember.title" />

      <ul className={css.rememberList}>
        {isElectrical ? (
          <li className={css.rememberItem}>
            <IconCheckmark rootClassName={css.greenTick} size="small" />{' '}
            <FormattedMessage id="ThingsToRemember.attachWarningLabel" values={{ warningLabel }} />
          </li>
        ) : null}
        <li className={css.rememberItem}>
          <IconCheckmark rootClassName={css.greenTick} size="small" />{' '}
          <FormattedMessage id="ThingsToRemember.forStorage" />
        </li>
        <li className={css.rememberItem}>
          <IconCheckmark rootClassName={css.greenTick} size="small" />{' '}
          <FormattedMessage id="ThingsToRemember.forCollection" />
        </li>
      </ul>
    </div>
  );
};

ThingsToRemember.defaultProps = {
  isElectrical: false,
  className: null,
};

ThingsToRemember.propTypes = {
  isElectrical: PropTypes.bool,
  intl: intlShape.isRequired,
  className: PropTypes.string,
};

export default ThingsToRemember;
